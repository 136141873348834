
import { defineComponent } from 'vue';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { useFiltrosStore } from '../store/filtros/index';
import { format } from 'date-fns';
import { IonCol, IonContent, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/vue';
import { caretDownOutline, caretUpOutline } from 'ionicons/icons';
import DashboardGraph from "../components/Charts/DashboardGraph.vue";

export default defineComponent({
  name: 'DashboardPage',
  components: {
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    DashboardGraph,
  },
  setup() {
    const store = useFiltrosStore();
    const slideOpts = {
      initialSlide: 1,
      speed: 400
    };

    return {
      slideOpts,
      format,
      store,
      caretUpOutline,
      caretDownOutline,
      modules: [Navigation, Pagination, Scrollbar, A11y]
    }
  }
});
