
/* eslint-disable vue/no-unused-components */
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import {
  IonButton, IonCol, IonCheckbox, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonPage, 
  IonRouterOutlet, IonRow, IonTabBar, IonTabButton, IonTabs, IonTitle, IonToolbar, alertController, modalController
} from '@ionic/vue';
import Header from '@/components/Header.vue';
import FiltrosContainer from '@/components/FiltrosContainer.vue';
import { useFiltrosStore } from '../store/filtros/index';
import { IconDataList } from '../dto/data/icon-data'
import { format } from 'date-fns';
import SelectBox from '../components/Widgets/SelectBox.vue';
import { TabEnum } from '@/dto/data/tab-enum';
import DashboardPage from './DashboardPage.vue'
import { crearSolicitudCargaDatos, getSolicitudDatosPendiente } from '@/services/UsuarioService';
import { useAuthStore } from '@/store/auth';

export default defineComponent({
  name: 'TabsPage',
  components: {
    IonButton, IonTabButton, IonIcon, IonItem, IonPage, IonRouterOutlet, IonRow, Header, SelectBox,
    IonCheckbox, IonCol, DashboardPage, IonContent, IonLabel, IonTabs, IonTabBar
  },
  setup() {
    const classTab = ref('md tab-has-label tab-has-icon tab-layout-icon-top ion-activatable ion-selectable ion-focusable');
    const classTabSelected = ref('md tab-has-label tab-has-icon tab-layout-icon-top ion-activatable ion-selectable ion-focusable tab-selected');
    const classTabNoSelected = ref('md tab-has-label tab-has-icon tab-layout-icon-top ion-activatable ion-selectable ion-focusable');
    const store = useFiltrosStore();
    const storeAuth = useAuthStore();

    onBeforeMount(async () => {
      getHaySolicitudDatosPendiente(false);
    });

    const getTabsWithoutDashboard = () => {
      return store.tabs;
    }

    const selectTab = async (tabLabel, tabId) => {
      await store.updateCurrentTabId(tabId);
      getHaySolicitudDatosPendiente();
    };

    // eslint-disable-next-line
    const checkTabDisabled = (tabId) => {
      return false;
    }

    const checkIvaFilterVisible = (tabId) => {
      return tabId == TabEnum.Compras && store.snapshot;
    }

    const getIcon = (IconName) => {
      return IconDataList.filter((x) => { return x.Name == IconName })[0].Icon;
    }

    const openFilters = async () => {
      const modal = await modalController
        .create({
          component: FiltrosContainer,
          componentProps: {
            title: 'Filtros'
          },
        })
      ;
      return modal.present();
    }

    const versionSnapshot = computed({
      get() {
        return store.currentVersionSnaphot?.Snapshot;
      },
      // eslint-disable-next-line
      set(newValue: any) {}
    });

    const changeMenuSelected = async (id, name) => {
      selectTab(name, id);
    }

    const newDataUpdateRequest = async () => {
      await crearSolicitudCargaDatos(storeAuth.codigoFarmacia)
        .then(async () => {
          getHaySolicitudDatosPendiente(false);

          const alert = await alertController.create({
            backdropDismiss: false,
            buttons: ['Aceptar'],
            header: 'Actualización de datos',
            message: 'Solicitud registrada. Recibirá una notificación cuando se resuelva.',
          });
          await alert.present();
        })
    }

    const getHaySolicitudDatosPendiente = (checkTime = true) => {
      getSolicitudDatosPendiente(storeAuth.codigoFarmacia, checkTime).then((x) => {
        store.setHaySolicitudDatosPendiente(x);
      });
    };

    return {
      FiltrosContainer,
      IonButton,
      IonCol,
      IonContent,
      IonHeader,
      IonItem,
      IonRow,
      IonTitle,
      IonToolbar,
      changeMenuSelected,
      checkIvaFilterVisible,
      checkTabDisabled,
      classTab,
      classTabNoSelected,
      classTabSelected,
      format,
      getIcon,
      getTabsWithoutDashboard,
      newDataUpdateRequest,
      openFilters,
      selectTab,
      store,
      versionSnapshot
    }
  }
});
