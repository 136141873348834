import CalculoMetricaDto from '@/dto/calculo-metrico-dto';
import { IndicadoresServiceEnum } from '@/dto/data/indicadores-service-enum';
import { baseHttpService } from "./BaseHttpService";

export async function calculoMetrica(param: CalculoMetricaDto) : Promise<any> {
    try {
        const indicador = IndicadoresServiceEnum.filter(function(x) { return x.Id === param.IndicadorId })[0];
        if (indicador) {
            const data = await baseHttpService.get('Metricas/' + indicador.Route, param, false);
            return [data, null];
        } else {
            return [null, null];
        }
    } catch(error) {
        return [null, error];
    }
}

export async function calculoMetricaLeyenda(param: CalculoMetricaDto) : Promise<any> {
    try {
        const indicador = IndicadoresServiceEnum.filter(function(x) { return x.Id === param.IndicadorId})[0];
        if (indicador) {
            const data = await baseHttpService.get('Metricas/CalculoMetricaLeyenda', param, false);
            return [data, null];
        } else {
            return [null, null];
        }
    } catch(error) {
        return [null, error];
    }
}
