import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "cellGridBorder-dash" }
const _hoisted_2 = { style: {"display":"flex","justify-content":"center","flex-wrap":"wrap","align-items":"center"} }
const _hoisted_3 = { class: "positive" }
const _hoisted_4 = { class: "negative" }
const _hoisted_5 = { class: "leyenda_row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_DashboardGraph = _resolveComponent("DashboardGraph")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      (_ctx.store.dashboardData.length > 0)
        ? (_openBlock(), _createBlock(_component_ion_item, {
            key: 0,
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "rowGrid" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.dashboardData, (element, index_el) => {
                    return (_openBlock(), _createBlock(_component_ion_col, {
                      key: 'cellDashboard' + index_el ,
                      class: "cellGrid-dash",
                      offset: "0",
                      size: "6"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_ion_label, { class: "tituloCell" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(element.titulo), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _withDirectives(_createVNode(_component_ion_label, { class: "valorPrincipal" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(element.valorPrincipal) + " € ", 1)
                            ]),
                            _: 2
                          }, 1536), [
                            [_vShow, element.valorPrincipal]
                          ]),
                          _withDirectives(_createVNode(_component_ion_label, { class: "porcentaje_row" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_2, [
                                _withDirectives(_createElementVNode("div", _hoisted_3, [
                                  _createVNode(_component_ion_label, { class: "row-pos-neg" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, { icon: _ctx.caretUpOutline }, null, 8, ["icon"]),
                                      _createTextVNode(" " + _toDisplayString(element.porcentajeSigno) + " " + _toDisplayString(element.porcentajeValor) + "% ", 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ], 512), [
                                  [_vShow, element.porcentajeSigno === '+']
                                ]),
                                _withDirectives(_createElementVNode("div", _hoisted_4, [
                                  _createVNode(_component_ion_label, { class: "row-pos-neg" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, { icon: _ctx.caretDownOutline }, null, 8, ["icon"]),
                                      _createTextVNode(" " + _toDisplayString(element.porcentajeValor) + "% ", 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ], 512), [
                                  [_vShow, element.porcentajeSigno === '-']
                                ]),
                                _createVNode(_component_ion_label, { style: {"margin-left":"2px"} }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" ( " + _toDisplayString(element.porcentajeReferencia) + "€)", 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ])
                            ]),
                            _: 2
                          }, 1536), [
                            [_vShow, element.porcentajeSigno]
                          ]),
                          _createElementVNode("div", _hoisted_5, _toDisplayString(element.leyenda), 1)
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_item, { lines: "none" }, {
        default: _withCtx(() => [
          _createVNode(_component_DashboardGraph)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}