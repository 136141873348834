
  import { defineComponent, onMounted, ref } from 'vue';
  import { IonButton, IonPage, IonInput, IonItem, IonContent, IonList, IonLabel, IonIcon } from '@ionic/vue';
  import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
  import ChangePasswordDto from '../dto/change-password-dto';
  import { useAuthStore } from '@/store/auth';
  import { useFiltrosStore } from '@/store/filtros';
  import { changePasswordUser } from '../services/UsuarioService';
  
  export default defineComponent({
    name: 'ChangePasswordPage',
    components: { IonButton, IonPage, IonInput, IonItem, IonContent, IonList, IonLabel, IonIcon },
    setup() {
    const storeAuth = useAuthStore();
      const usuarioChangePass = new ChangePasswordDto();
      const authStore = useAuthStore();
      const password = ref("");
      const newPassword = ref("");
      const newPasswordRepeat = ref("");
      const filtrosStore = useFiltrosStore();
  
      onMounted(async () => {
        console.log("Change Pass");
      })

      const changePassword = async () => {
        usuarioChangePass.NombreUsuario = authStore.username.trim();
        usuarioChangePass.Clave = password.value;
        usuarioChangePass.NuevaClave = newPassword.value;
        usuarioChangePass.NuevaClaveRepetida = newPasswordRepeat.value;

        if(authStore.username.trim().length >0)
        {
          changePasswordUser(usuarioChangePass).then((x) => {                
              if (x != null && x != undefined) {
                  storeAuth.$reset();   
                  filtrosStore.resetFilters();
                  filtrosStore.$reset();            
                  storeAuth.token = "";
                  storeAuth.refreshToken = "";
                  storeAuth.nombreFarmacia = "";
                  storeAuth.codigoFarmacia = "";
                  storeAuth.username = "";
                  localStorage.clear();              
                  //Llamamos a login page
                  window.location.href = "/login";                    
              }
          });
        }
      }
  
      const showPassword = ref(false);
      const showNewPassword = ref(false);
      const showNewPasswordRepeat = ref(false);
      const typePassword = ref("password");
      const typeNewPassword = ref("password");
      const typeNewPasswordRepeat = ref("password");
  
      const toggleShow = () => {
        showPassword.value = !showPassword.value;
        typePassword.value = showPassword.value ? 'text' : 'password';
      }

      const toggleNewShow = () => {
        showNewPassword.value = !showNewPassword.value;
        typeNewPassword.value = showNewPassword.value ? 'text' : 'password';
      }

      const toggleNewRepeatShow = () => {
        showNewPasswordRepeat.value = !showNewPasswordRepeat.value;
        typeNewPasswordRepeat.value = showNewPasswordRepeat.value ? 'text' : 'password';
      }
  
      return {
        usuarioChangePass,
        changePassword,
        password,
        newPassword,
        newPasswordRepeat,
        authStore,
        toggleShow,
        toggleNewShow,
        toggleNewRepeatShow,        
        showPassword,
        showNewPassword,
        showNewPasswordRepeat,
        typePassword,
        typeNewPassword,
        typeNewPasswordRepeat,
        eyeOffOutline,
        eyeOutline,
      }
    }
  });
