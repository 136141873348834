import { defineStore } from 'pinia'
import { login } from '../../services/SecurityService';
import {isCurrentTokenValid} from '../../services/UsuarioService';
import UserDto from '@/dto/user-dto';
import { useStorage } from '@vueuse/core'

export const useAuthStore= defineStore('authStore', {
  state: () => {
    return {
      username: useStorage('username', ''),
      codigoFarmacia: useStorage('codigoFarmacia', ''),
      token: useStorage('token', ''),
      refreshToken: useStorage('refreshToken', ''),
      tokenFirebase: useStorage('tokenFirebase', ''),
      nombreFarmacia: useStorage('nombreFarmacia', ''),
      solicitarPass: useStorage('solicitarPass', false)
    }
  },
  actions: {
    async login(user: UserDto) {
      user.TokenFirebase = this.tokenFirebase;
      const resultLogin = await login(user);
      if (resultLogin) {
        this.solicitarPass = resultLogin.solicitarPass;
        if (this.solicitarPass == false){
          this.token = resultLogin.token;
          this.refreshToken = resultLogin.refreshToken;
          this.username = user.NombreUsuario;
          this.codigoFarmacia = user.CodigoFarmacia;
          this.nombreFarmacia = user.NombreFarmacia;
        }
      }
      return resultLogin;
    },
    async isCurrentTokenValid(){
      return await isCurrentTokenValid();
    }
  },
})