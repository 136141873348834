
import { defineComponent, ref, computed } from 'vue';
import { 
  IonButton, IonButtons, IonCol, IonContent, IonDatetime, IonHeader, IonItem, IonLabel, 
  IonList, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar, modalController 
} from '@ionic/vue';
import { format } from 'date-fns';
import { es } from 'date-fns/esm/locale';
import { useFiltrosStore } from '../store/filtros/index';

export default defineComponent({
  name: 'FiltrosContainer',
  components: { IonButton, IonButtons, IonCol, IonContent, IonDatetime, IonHeader, IonItem, IonLabel, IonList, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar },
  setup() {
    const store = useFiltrosStore();
    const params = ref(store.calculoMetricaDto);
    const dateSelected = ref(new Date());
    const dateLabel = ref('Mes');

    const dateSelectedFormated = computed({
      get() {
        return store.calculoMetricaDto.FechaIndicador.toISOString();
      },
      set(newValue: string) {
        store.setFechasSinActualizar(new Date(newValue));
      }
    });

    const dateSelectedString = computed({
      get() {
        return format(store.calculoMetricaDto.FechaIndicador, 'dd MMMM yyyy', { locale: es });
      },
      // eslint-disable-next-line
      set(newValue: string) {
        // var a = newValue;
      }
    });

    const empresaId = computed({
      get() {
        return store.calculoMetricaDto.EmpresaId;
      },
      set(newValue: number) {
        params.value.EmpresaId = newValue;
        store.updateCalculoMetricaDto(params.value);
      }
    });

    const almacenId = computed({
      get() {
        return store.calculoMetricaDto.AlmacenId;
      },
      set(newValue: number) {
        params.value.AlmacenId = newValue;
        store.updateCalculoMetricaDto(params.value);
      }
    });

    const datePresentation = ref('date');

    const applyFilters = async () => {
      await store.calculoTodasMetricasByIndicadores(store.indicadores);
      await store.updateVersionesSnapshots()
      modalController.dismiss();
    }

    return {
      almacenId,
      applyFilters,
      dateLabel,
      datePresentation,
      dateSelected,
      dateSelectedFormated,
      dateSelectedString,
      empresaId,
      es,
      format,
      store
    }
  }
});
