
import { defineComponent, onMounted, ref } from 'vue';
import { IonButton, IonPage, IonInput, IonItem, IonContent, IonList, IonLabel, IonIcon } from '@ionic/vue';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import UserDto from '../dto/user-dto';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/auth';
import { getFarmaciasByUsuario } from '../services/UsuarioService';
import SelectBoxString from '../components/Widgets/SelectBoxString.vue';

export default defineComponent({
  name: 'LoginPage',
  components: { IonButton, IonPage, IonInput, IonItem, IonContent, IonList, IonLabel, IonIcon, SelectBoxString },
  setup() {
    const usuario = new UserDto();
    const router = useRouter();
    const authStore = useAuthStore();
    const password = ref("");
    const farmacias = ref([]);
    const farmaciaSelected = ref("");

    onMounted(async () => {
      if (authStore.token !== null && authStore.token !== undefined && authStore.token !== '') {
        if (await authStore.isCurrentTokenValid()) {
          router.push({ path: '/tabs' });
        }
        else {
          console.log(' token no valid');
        }
      }
    })

    const login = async () => {
      authStore.nombreFarmacia = farmacias.value.find(x => x.FarmaciaCodigo === farmaciaSelected.value).FarmaciaDescripcion;
      authStore.codigoFarmacia = farmaciaSelected.value;
      usuario.CodigoFarmacia = authStore.codigoFarmacia;
      usuario.NombreUsuario = authStore.username.trim();
      usuario.Password = password.value;
      usuario.NombreFarmacia = authStore.nombreFarmacia.trim();
      authStore.login(usuario).then((data) => {
        if (data && data !== '') {
          if (data.solicitarPass == true){
            router.push({ path: '/changePass' });
          }
          else{
            router.push({ path: '/tabs' });
          }
          
        }
        else {
          return 'ERROR'
        }
      })
    }

    const cargaFarmacias = async () => {
      if(authStore.username.trim().length >0) {
        getFarmaciasByUsuario(authStore.username.trim()).then((x) => {
          if (x.length === 0 || x[0] === null) {
            farmacias.value = [];
            farmaciaSelected.value = "";
            authStore.codigoFarmacia = "";
            authStore.nombreFarmacia = "";
            return;
          }

          farmacias.value = x;
          farmaciaSelected.value = x[0].FarmaciaCodigo;
          authStore.codigoFarmacia = x[0].FarmaciaCodigo;
          authStore.nombreFarmacia = x[0].FarmaciaDescripcion;
        });
      }
    }

    const showPassword = ref(false);
    const typePassword = ref("password");
    const toggleShow = () => {
      showPassword.value = !showPassword.value;
      typePassword.value = showPassword.value ? 'text' : 'password';
    }

    return {
      usuario,
      login,
      password,
      authStore,
      toggleShow,
      cargaFarmacias,
      showPassword,
      typePassword,
      eyeOffOutline,
      eyeOutline,
      getFarmaciasByUsuario,
      farmacias,
      farmaciaSelected,
    }
  }
});
