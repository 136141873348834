
import { defineComponent, onMounted, ref } from "vue";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButtons,
  IonButton,
  menuController,
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonBackButton,
  IonLabel,
  IonBadge,
} from "@ionic/vue";
import { exitOutline, menuOutline, notificationsOutline, optionsOutline } from "ionicons/icons";
import { IconDataList } from "../dto/data/icon-data";
import { useFiltrosStore } from "../store/filtros/index";
import { useAuthStore } from "../store/auth/index";
import {
  getNotificacionesByFarmaciaUsuario,
  getNumeroNotificacionesSinLeer,
  marcarNotificacionesComoLeidas,
} from "../services/UsuarioService";
import CalculoMetricaDto from "@/dto/calculo-metrico-dto";
import { logoutFirebase } from "../services/SecurityService";
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/esm/locale';

export default defineComponent({
  name: "FiltrosContainer",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonIcon,
    IonButtons,
    IonButton,
    IonMenu,
    IonContent,
    IonList,
    IonItem,
    IonBackButton,
    IonLabel,
    IonBadge,
  },
  emits: ["open-filter", "change-menu-selected"],
  props: {
    pageTitle: String,
    cssClass: String,
    condense: String,
    sizeTitle: String,
  },
  setup(props, { emit }) {
    const storeAuth = useAuthStore();
    const store = useFiltrosStore();
    const nombreFarmacia = storeAuth.nombreFarmacia;
    const nombreFarmaciaFormateado = ref("");

    onMounted(async () => {
      const calculoMetricaDto = new CalculoMetricaDto();
      calculoMetricaDto.FarmaciaId = parseInt(storeAuth.codigoFarmacia);
      
      store.updateCalculoMetricaDto(calculoMetricaDto);
      await store.calculaDashboard();
      await store.updateVersionesSnapshots();
      await store.loadFilters();
      await store.getTabs();

      store.getNotificacionesSinLeer();

      nombreFarmaciaFormateado.value = nombreFarmacia.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    });

    const changeMenuSelected = (id, name) => {
      menuController.close("app-menu");
      emit("change-menu-selected", id, name);
    };

    const openFilter = () => {
      emit("open-filter");
    };

    const format_date = (value: string) => {
      if (value) {
        return format(parseISO(value), "dd/MM/yyyy HH:mm", { locale: es });
      }
    };

    const openMenu = () => {
      menuController.enable(true, "app-menu");
      menuController.open("app-menu");
    };

    const openNotifications = () => {
      menuController.enable(true, "app-notifications");
      menuController.open("app-notifications");
      getNotificacionesByFarmaciaUsuario().then((x) => {
        store.notificaciones = x;
      });
    };

    const closeNotifications = () => {
      menuController.enable(true, "app-notifications");
      menuController.close("app-notifications");
      marcarNotificacionesComoLeidas().then(() => {
        getNumeroNotificacionesSinLeer().then((x) => {
          store.setNotificacionesSinLeer(x.NotificacionesSinLeer);
        });
      });
    };

    const logout = async () => {
      if (!storeAuth.tokenFirebase || storeAuth.tokenFirebase.length === 0) {
        cleanAtLogout();
        return;
      }

      await logoutFirebase(storeAuth.tokenFirebase).then(() => {
        cleanAtLogout();
      });
    };

    const cleanAtLogout = () => {
      menuController.close("app-menu");
      storeAuth.$reset();
      store.resetFilters();
      localStorage.clear();
      store.$reset();
      storeAuth.username = "";
      storeAuth.token = "";
      storeAuth.refreshToken = "";
      storeAuth.nombreFarmacia = "";
      storeAuth.codigoFarmacia = "";
      storeAuth.tokenFirebase = "";
      window.location.href = "/login";
    };

    const getIcon = (IconName) => {
      return IconDataList.filter((x) => {
        return x.Name == IconName;
      })[0].Icon;
    };

    return {
      changeMenuSelected,
      closeNotifications,
      exitOutline,
      format_date,
      getIcon,
      getNotificacionesByFarmaciaUsuario,
      logout,
      menuOutline,
      nombreFarmacia,
      nombreFarmaciaFormateado,
      notificationsOutline,
      openFilter,
      openMenu,
      openNotifications,
      optionsOutline,
      store,
    };
  },
});
