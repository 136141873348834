import { baseHttpService } from "./BaseHttpService";
import ChangePasswordDto from '../dto/change-password-dto';
import { useFiltrosStore } from "../store/filtros/index";

const minTiempoEntreGets = 3 * 60 * 1000; // 3 minutos en milisegundos

export async function getFarmaciasByUsuario(idUsuario: string): Promise<any> {
  try {
    const data = await baseHttpService.get(
      "Usuario/" + idUsuario + "/Farmacias",
      false
    );
    return data;
  } catch (error) {
    return [null, error];
  }
}

export async function getNotificacionesByFarmaciaUsuario(): Promise<any> {
  try {
    const data = await baseHttpService.get(
      "Notificaciones/GetNotificacionesHistorico",
      false,
      true
    );
    return data;
  } catch (error) {
    return [null, error];
  }
}

export async function marcarNotificacionesComoLeidas(): Promise<any> {
  try {
    const data = await baseHttpService.post(
      "Notificaciones/MarcaNotificacionesLeidas",
      false
    );
    return data;
  } catch (error) {
    return [null, error];
  }
}

export async function getNumeroNotificacionesSinLeer(): Promise<any> {
  try {
    const data = await baseHttpService.get(
      "Notificaciones/GetNumeroNotificacionesSinLeer",
      false
    );
    return data;
  } catch (error) {
    return [null, error];
  }
}

export async function isCurrentTokenValid(): Promise<boolean> {
  try {
    await baseHttpService.get("Usuario/IsAuth", false);
    return true;
  } catch (error) {
    return false;
  }
}

export async function changePasswordUser(userPass: ChangePasswordDto) {   
  return await baseHttpService.post("Usuario/ChangePassword", userPass, true);
}

export async function getSolicitudDatosPendiente(codigoFarmacia: string, checkElapsedTime = true): Promise<any> {
  try {
    const useStore = useFiltrosStore();

    if (checkElapsedTime === true) {
      const elapsedTime = Date.now() - useStore.ultimaComprobacionSolicitudDatosPendiente.getTime();
      if (elapsedTime < minTiempoEntreGets) {
        return useStore.haySolicitudDatosPendiente;
      }
    }

    const data = await baseHttpService.get(
      "Farmacia/HasPendingRequests/App/" + codigoFarmacia,
      false
    );

    useStore.setFechaUltimaComprobacionSolicitudDatosPendiente(new Date());

    useStore.getNotificacionesSinLeer();

    return data;
  } catch (error) {
    return [null, error];
  }
}

export async function crearSolicitudCargaDatos(codigoFarmacia: string): Promise<any> {
  return await baseHttpService.post("Farmacia/CreateRequestDataUpdate/" + codigoFarmacia, null, true);
}
