import { baseHttpService } from "./BaseHttpService";

export async function getIndicadoresByTab(tabId): Promise<any[]> {
    try {
        const data = await baseHttpService.get("Dimensiones/GetIndicadoresByTab", { tabId }, false);
        return [data,null];
    } catch(error) {
        return [null, error];
    }
}

export async function getTabs(): Promise<any[]> {
    try {
        const data = await baseHttpService.get('Dimensiones/GetTabs', false);
        return [data, null];
    } catch(error) {
        return [error];
    }
}

export async function getAlmacenes(): Promise<any[]> {
    try {
        const data = await baseHttpService.get('Dimensiones/GetAlmacenes', null, false);
        return [data, null];
    } catch(error) {
        return [null,error];
    }
}

export async function getCajas(): Promise<any[]> {
    try {
        const data = await baseHttpService.get('Dimensiones/GetCajas', null, false);
        return [data, null];
    } catch(error) {
        return [null,error];
    }
}

export async function getEmpresas(): Promise<any[]> {
    try {
        const data  = await baseHttpService.get('Dimensiones/GetEmpresas', null, false);
        return [data, null];
    } catch(error) {
        return [null,error];
    }
}

export async function getEstados(): Promise<any[]> {
    try {
        const data  = await baseHttpService.get('Dimensiones/GetEstados', null, false);
        return [data, null];
    } catch(error) {
        return [null,error];
    }
}

export async function getFarmacias(spinner = false): Promise<any[]> {
    try {
        const data  = await baseHttpService.get('Dimensiones/GetFarmacias', null, spinner);
        return [data, null];
    } catch(error) {
        return [null,error];
    }
}

export async function getFechaDia(): Promise<any[]> {
    try {
        const data  = await baseHttpService.get('Dimensiones/GetFechaDia', null, false);
        return [data, null];
    } catch(error) {
        return [null,error];
    }
}

export async function getGrupoFarmacia(): Promise<any[]> {
    try {
        const data  = await baseHttpService.get('Dimensiones/GetGrupoFarmacia', null, false);
        return [data, null];
    } catch(error) {
        return [null,error];
    }
}
