import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTitle = _resolveComponent("DxTitle")!
  const _component_DxZoomAndPan = _resolveComponent("DxZoomAndPan")!
  const _component_DxCommonSeriesSettings = _resolveComponent("DxCommonSeriesSettings")!
  const _component_DxSeries = _resolveComponent("DxSeries")!
  const _component_DxLegend = _resolveComponent("DxLegend")!
  const _component_DxTooltip = _resolveComponent("DxTooltip")!
  const _component_DxChart = _resolveComponent("DxChart")!

  return (_openBlock(), _createBlock(_component_DxChart, {
    id: "graficodashboard",
    "data-source": _ctx.store.dashboardGraphic,
    top: "0",
    valueMarginsEnabled: "false"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DxTitle, { text: "Evolución" }),
      _createVNode(_component_DxZoomAndPan, {
        argumentAxis: "both",
        valueAxis: "both"
      }),
      _createVNode(_component_DxCommonSeriesSettings, { type: "bar" }),
      _createVNode(_component_DxSeries, {
        "value-field": "totalventas",
        name: "Ventas",
        color: "#D9514EFF"
      }),
      _createVNode(_component_DxSeries, {
        "value-field": "totalcompras",
        name: "Compras",
        color: "#3880ff"
      }),
      _createVNode(_component_DxLegend, {
        "vertical-alignment": "bottom",
        "horizontal-alignment": "center",
        "item-text-position": "right"
      }),
      _createVNode(_component_DxTooltip, {
        enabled: true,
        "customize-tooltip": _ctx.customizeTooltip
      }, null, 8, ["customize-tooltip"])
    ]),
    _: 1
  }, 8, ["data-source"]))
}