
import { defineComponent } from 'vue';
import { IonSelect, IonSelectOption, } from '@ionic/vue';

export default defineComponent({
  name: 'SelectBoxObject',
  components: { IonSelect, IonSelectOption },
  emits: ['update:modelValue'],
  props: {
    options: [],
    text: String,
    id: String,
    modelValue: String
  },
  // eslint-disable-next-line
  setup(props, { emit }) {

    const updateValue = (event) => {
      emit('update:modelValue', event.target.value);
    }
    const selectOptions = {
      cssClass: 'remove-ok'
    };

    return {
      updateValue,
      selectOptions
    }
  }
});
