
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent, provide } from 'vue';
import esMessages from "devextreme/localization/messages/es.json";
import firebaseMessaging from './messaging/firebase';
import { locale, loadMessages } from "devextreme/localization";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup() {
    const router = useRouter();
    provide('messaging', firebaseMessaging);
    loadMessages(esMessages);
    locale("es-ES");
    
    return {
      router
    }
  }
});
