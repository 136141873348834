import CalculoMetricaDto from "@/dto/calculo-metrico-dto";
import { TabEnum } from '@/dto/data/tab-enum';
import { TipoIndicador } from "@/dto/data/tipo-indicador-enum";
import { calculoMetrica, calculoMetricaLeyenda } from "../../services/MetricasService";
import { defineStore } from "pinia";
import { getAlmacenes, getEmpresas, getIndicadoresByTab, getTabs } from "../../services/DimensionesService";
import { getDashboard } from "../../services/DashboardService";
import { getNumeroNotificacionesSinLeer } from "@/services/UsuarioService";
import { getSnapshot, getSnapshotVersiones } from "../../services/SnapshotsService";

export const useFiltrosStore = defineStore("filtrosStore", {
  state: () => {
    return {
      activeIndex: 0,
      almacenes: [],
      calculoMetricaDto: new CalculoMetricaDto(),
      currentIndicador: null,
      currentTab: null,
      currentTabId: 0,
      currentVersionSnaphot: null,
      dashboardData: [],
      dashboardGraphic: [],
      dataSourceIndicador: [],
      dataSourceIndicadorLeyenda: "",
      dataSourceTodosIndicadores: [],
      dataSourceTodosIndicadoresLeyenda: {},
      empresas: [],
      haySolicitudDatosPendiente: true,
      indicadores: [],
      notificaciones: [],
      notificacionesSinLeer: 0,
      selectedMetricaInstantanea: TipoIndicador.Instantanea,
      snapshot: false,
      snapshots: [],
      tabs: [],
      ultimaComprobacionSolicitudDatosPendiente: new Date(),
      versionesSnapshots: [],
    };
  },
  actions: {
    async setNotificacionesSinLeer(notificacionesSinLeer) {
      this.notificacionesSinLeer = notificacionesSinLeer;
    },

    getNotificacionesSinLeer() {
      getNumeroNotificacionesSinLeer().then((x) => {
        this.setNotificacionesSinLeer(x.NotificacionesSinLeer);
      });
    },

    async getIndicadores(tabId) {
      const [data, error] = await getIndicadoresByTab(tabId);
      if (error) console.error(error);
      else if (data) {
        this.indicadores = data.sort((a,b) => a.IndicadorOrden - b.IndicadorOrden);
        this.currentIndicador = data[0];
        this.calculoTodasMetricasByIndicadores(this.indicadores);
      }
      return this.indicadores;
    },

    async getEmpresas() {
      const [data, error] = await getEmpresas();
      if (error) console.error(error);
      else {
        this.empresas = data;
      }
    },
    
    async getAlmacenes() {
      const [data, error] = await getAlmacenes();
      if (error) console.error(error);
      else {
        this.almacenes = data;
      }
    },

    async getTabs() {
      const [data, error] = await getTabs();
      if (error) console.error(error);
      else {
        this.tabs = data;
        if (this.tabs?.length > 0) {
          await this.setCurrentTab(this.tabs[0]);
        }
      }
    },
    
    async setCurrentTab(value) {
      this.activeIndex = 0;
      this.currentTab = value;
      this.currentTabId = value.TabId;
      if (this.currentTabId !== 999) {
        this.calculoMetricaDto.TabId = value.TabId;
        this.resetData();
        if (this.currentTabId === TabEnum.Seguridad) {
          this.selectedMetricaInstantanea = TipoIndicador.Instantanea;
        } else {
          await this.getIndicadores(value.TabId);
        }
        await this.updateVersionesSnapshots();
      }
    },

    async updateCurrentTabId(value) {
      // Set TabId
      this.setCurrentTab(
        this.tabs.filter((x) => {
          return x.TabId == value;
        })[0]
      );
    },

    setCurrentIndicadorFromSwipe(value) {
      this.currentIndicador = value;
      this.calculoMetricaDto.IndicadorId = this.currentIndicador.IndicadorId;
      this.calculoMetricaDto.IndicadorNombre = this.currentIndicador.IndicadorNombre;
    },
    
    async calculaDashboard() {
      const [data, error] = await getDashboard();
      if (error) console.error(error);
      else if (data) {
        this.dashboardData = data.Item1.map((x) => ({
          ...x,
          porcentajeValor: Number(x.porcentajeValor)?.toLocaleString(
            process.env.VUE_APP_CULTURE,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          ),
          porcentajeReferencia: Number(x.porcentajeReferencia)?.toLocaleString(
            process.env.VUE_APP_CULTURE,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          ),
          valorPrincipal:
            x.valorPrincipal === null
              ? null
              : x.valorPrincipal.toLocaleString(process.env.VUE_APP_CULTURE, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
        }));

        this.dashboardGraphic = data.Item2.map((x) => ({
          ...x,
          arg:
            new String(
              new Date(x.anio, x.mes - 1, 1).toLocaleString("es-es", {
                month: "short",
              })
            ) +
            "-" +
            new String(x.anio).substring(2, 4),
        }));
      }

      this.dashboardGraphic.reverse();
      // this.dashboardGraphic.unshift(this.dashboardGraphic.pop());
    },

    updateCalculoMetricaDto(dto: CalculoMetricaDto) {
      this.calculoMetricaDto = dto;
    },

    updateSnapshot(value) {
      this.snapshot = value;
    },

    async calculoTodasMetricasByIndicadores(indicadores : any[]) {
      // no se hace para Dashboard, ni Seguridad
      if (this.currentTabId === 999 || this.currentTabId === TabEnum.Seguridad) {
        return;
      }
      this.dataSourceTodosIndicadores = [];
      this.dataSourceTodosIndicadoresLeyenda = {};

      const calculoMetricaIndex: CalculoMetricaDto = new CalculoMetricaDto();
      calculoMetricaIndex.AlmacenId = this.calculoMetricaDto.AlmacenId;
      calculoMetricaIndex.EmpresaId = this.calculoMetricaDto.EmpresaId;
      calculoMetricaIndex.FarmaciaId = this.calculoMetricaDto.FarmaciaId;
      calculoMetricaIndex.FechaIndicador = this.calculoMetricaDto.FechaIndicador;
      calculoMetricaIndex.FechaSnapshot = this.calculoMetricaDto.FechaSnapshot;
      calculoMetricaIndex.IndicadorId = this.calculoMetricaDto.IndicadorId;
      calculoMetricaIndex.IndicadorNombre = this.calculoMetricaDto.IndicadorNombre;
      calculoMetricaIndex.Iva = this.calculoMetricaDto.Iva;
      calculoMetricaIndex.TabId = this.calculoMetricaDto.TabId;
      calculoMetricaIndex.VersionSnapshot = this.calculoMetricaDto.VersionSnapshot;

      indicadores.forEach(async indicador => {
        // la comparativa por mes se gestiona en MedidasPage
        if (indicador.IndicadorId === 17) {
          return
        }
        calculoMetricaIndex.IndicadorId = indicador.IndicadorId;
        calculoMetricaIndex.IndicadorNombre = indicador.IndicadorNombre;

        const [data, error] = await calculoMetrica(calculoMetricaIndex);

        if (error) console.error(error);
        else {
          this.dataSourceTodosIndicadores[indicador.IndicadorId] = data.map((x) => ({
            ...x,
            etiquetaDia: "Día " + (indicador.IndicadorId != 17 ? new String(new Date(x.etiqueta).getDate()) : x.etiqueta),
          }));
        }

        if (indicador?.IndicadorLeyenda == 1) {
          const resultLeyenda = await calculoMetricaLeyenda(calculoMetricaIndex);
          const [dataLeyenda, errorLeyenda] = resultLeyenda;
  
          if (errorLeyenda) console.error(errorLeyenda);
          else {
            this.dataSourceTodosIndicadoresLeyenda[indicador.IndicadorNombre] = dataLeyenda.Leyenda;
          }
        }
      });
    },

    async updateIndicador(calculoMetricaData: CalculoMetricaDto) {
      const [data, error] = await calculoMetrica(calculoMetricaData);

      if (error) console.error(error);
      else {
        this.dataSourceTodosIndicadores[calculoMetricaData.IndicadorId] = data.map((x) => ({
          ...x,
          etiquetaDia: "Día " + (calculoMetricaData.IndicadorId != 17 ? new String(new Date(x.etiqueta).getDate()) : x.etiqueta),
        }));
      }
    },

    resetData() {
      this.dataSourceIndicador = [];
      this.dataSourceIndicadorLeyenda = "";
      this.calculoMetricaDto.IndicadorNombre = "";
      this.snapshots = [];
    },

    resetFilters() {
      this.calculoMetricaDto = new CalculoMetricaDto();
      this.indicadores = [];
      this.snapshot = false;
      this.dataSourceIndicadorLeyenda = "";
      this.calculoMetricaDto.IndicadorNombre = "";
      this.snapshots = [];
    },

    async loadFilters() {
      await this.getEmpresas();
      await this.getAlmacenes();
    },

    getFarmacia() {
      return this.calculoMetricaDto.FarmaciaId;
    },

    async setFechaIndicador(value) {
      // El controlador de calendario es llamado dos veces cuando se realiza un cambio. Si las fechas son iguales no se debe de recalcular los indicadores
      if (
        value.getMonth() === this.calculoMetricaDto.FechaIndicador.getMonth() &&
        value.getFullYear() === this.calculoMetricaDto.FechaIndicador.getFullYear() &&
        value.getDay() === this.calculoMetricaDto.FechaIndicador.getDay()
      ) {
        return;
      }

      // Solo se actualizan las metricas cuando hay un cambio de mes
      if (
        value.getMonth() !== this.calculoMetricaDto.FechaIndicador.getMonth() ||
        value.getFullYear() !== this.calculoMetricaDto.FechaIndicador.getFullYear()
      ) {
        this.calculoMetricaDto.FechaIndicador = value;
        //this.calculoMetrica();
        this.calculoTodasMetricasByIndicadores(this.indicadores)
      }
      // this.calculoMetricaDto.FechaIndicador = value;
      await this.setFechaSnapshot(value);
    },

    async setFechaSnapshot(value: Date) {
      this.calculoMetricaDto.FechaSnapshot = value;
      this.updateVersionesSnapshots();
    },

    async setFechasSinActualizar(value: Date) {
      this.calculoMetricaDto.FechaIndicador = value;
      this.calculoMetricaDto.FechaSnapshot = value;
    },

    updateIva(value) {
      this.calculoMetricaDto.Iva = value;
      this.updateSnapshotGraphics();
    },

    setHaySolicitudDatosPendiente(value: boolean) {
      this.haySolicitudDatosPendiente = value;
    },

    setFechaUltimaComprobacionSolicitudDatosPendiente(value: Date) {
      this.ultimaComprobacionSolicitudDatosPendiente = value;
    },

    /////////////////////////

    async updateVersionesSnapshots() {      
      await getSnapshotVersiones(this.getFarmacia(), this.calculoMetricaDto.FechaSnapshot)
        .then((versionesSnapshot) => {
          const [data, error] = versionesSnapshot;
          if (error) console.error(error);
          else {
            this.versionesSnapshots = data;
            if (this.versionesSnapshots?.length > 0) {
              this.setCurrentVersionSnaphot(this.versionesSnapshots[0]);
            }
            else{
              this.currentVersionSnaphot = null;
              this.calculoMetricaDto.VersionSnapshot = null;
            }
          }
        })
      ;
    },

    async setCurrentVersionSnaphot(value) {
      this.currentVersionSnaphot = value;
      this.calculoMetricaDto.VersionSnapshot = value.VersionNumero;
      this.updateSnapshotGraphics();
    },

    async updateSnapshotGraphics() {
      const [data, error] = await getSnapshot(this.calculoMetricaDto);
      if (error) console.error(error);
      else {
        this.snapshots = data;
      }
    },
  },
});
