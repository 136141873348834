export const IndicadoresServiceEnum = [
    { Id:1  , Route:'CalculoPorcentajeVentasLibreSeguro'},
    { Id:2  , Route:'CalculoPorcentajeImporteTarjetaLibre'},
    { Id:3  , Route:'CalculoPorcentajeImporteTarjetaSeguro'},
    { Id:4  , Route:'CalculoImporteMedioLibreSeguroTotal'},
    { Id:5  , Route:'CalculoMediaUnidadesMedioLibreSeguroTotal'},
    { Id:6  , Route:'CalculoEvolucionTotalImporteUnidades'},//Evolución del total importe y unidades
    { Id:7  , Route:'CalculoDiarioPicoMaximoImporteUnidades'},
    { Id:8  , Route:'CalculoSaldoEfectivoPagoTarjeta'},
    { Id:9  , Route:'CalculoSaldoActual'},
    { Id:10 , Route:'CalculoSaldoMedioTendencia'},
    { Id:11 , Route:'CalculoEvolucionTotalImporteUnidadesCompras'},
    { Id:12 , Route:'CalculoDiarioPicoMaximoImporteUnidadesCompras'},
    { Id:13 , Route:'CalculoArticulosPVPCero'},
    { Id:14 , Route:'CalculoInventario'},
    { Id:15 , Route:'CalculoStockPositivo'},
    { Id:16 , Route:'CalculoInventarioValoradoPrecioMedioPonderadoIva'},
    { Id:17 , Route:'CalculoVentasComparativaPorMes'}
]
