import { baseHttpService } from "./BaseHttpService";

export async function getDashboard() : Promise<any> {
    try {
        const data = await baseHttpService.get('Dashboard/GetDashboard', null, false);
        return [data, null];
    } catch(error) {
        return [null, error];
    }
}
