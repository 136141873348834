import { walletOutline, peopleOutline, optionsOutline, cashOutline, cartOutline, fileTrayStackedOutline, podiumOutline, lockOpenOutline } from 'ionicons/icons';

export const IconDataList = [
    { Name: 'dashboard' , Icon: podiumOutline},
    { Name: 'credit-card' , Icon: walletOutline},
    { Name: 'globe-outline' , Icon: cashOutline},
    { Name: 'people-outline' , Icon: peopleOutline},
    { Name: 'bag-handle-outline' , Icon: cartOutline},
    { Name: 'file-tray-full-outline' , Icon: fileTrayStackedOutline},
    { Name: 'bag-check-outline' , Icon: lockOpenOutline},
    { Name: 'options-outline' , Icon: optionsOutline},
]
