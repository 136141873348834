import axios, { AxiosInstance } from "axios";
import { loadingController, toastController } from "@ionic/vue";
import authHeader from "./AuthHeader";

export class BaseHttpService {
  protected axiosClient: AxiosInstance;
  protected loading;

  public InitAxiosInstance(store: any) {
    const axiosClient = axios.create({
      baseURL: process.env.VUE_APP_API_URI,
    });

    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error, token = null) => {
      failedQueue.forEach((prom) => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve(token);
        }
      });

      failedQueue = [];
    };
    axiosClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        const originalRequest = err.config;
        if (
          originalRequest.url === "/Security/refreshtoken" &&
          err.response.status === 401
        ) {
          store.$reset();
          store.token = "";
          store.refreshToken = "";
          store.nombreFarmacia = "";
          store.codigoFarmacia = "";
          store.username = "";
          localStorage.clear();
          window.location.href = "/login";
        }
        if (
          originalRequest.url !== "/Security/login"  && originalRequest.url !== "Usuario/IsAuth" && 
          err.response.status === 401 &&
          !originalRequest._retry
        ) {
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers["Authorization"] = "Bearer " + token;
                return axios(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          return new Promise(function (resolve, reject) {
            axiosClient
              .post("/Security/refreshtoken", {
                userName: store.username,
                codigoFarmacia: store.codigoFarmacia,
                refreshtoken: store.refreshToken,
              })
              .then((data) => {
                originalRequest.headers["Authorization"] =
                  "Bearer " + data.data.token;
                store.refreshToken = data.data.refreshToken;
                store.token = data.data.token;
                processQueue(null, data.data.token);
                resolve(axiosClient(originalRequest));
                if(window.location.pathname === '/login' || window.location.pathname === '' )
                {
                  window.location.href = "/tabs/master";
                }
              })
              .catch((err) => {
                processQueue(err, null);
                reject(err);
              })
              .then(() => {
                isRefreshing = false;
              });
          });
        }

        return Promise.reject(err);
      }
    );
    this.axiosClient = axiosClient;
    return axiosClient;
  }

  public async createLoading() {
    this.loading = await loadingController.create({
      cssClass: "my-custom-class",
      message: "Cargando...",
    });
  }

  public async openToast(message) {
    const toast = await toastController.create({
      message: message,
      duration: 2000,
    });
    return toast.present();
  }

  public async get(url, params?, showLoading?): Promise<any> {
    if (showLoading) {
      await this.createLoading();
      await this.loading.present();
    }

    try {
      const { data } = await this.axiosClient.get(url, {
        params: params,
        headers: authHeader(),
      });
      return data;
    } catch (error: any) {
      let msg: string = error.response?.data.message;

      if (msg === undefined || msg.length === 0) {
        msg = 'Se ha producido un error inesperado. Vuelva a intentarlo más tarde.';
      }

      this.openToast(
        await this.capitalizeMessage(msg, true)
      );
    } finally {
      if (showLoading) this.loading.dismiss();
    }
  }

  public async post(url, params?, showLoading?): Promise<any> {
    if (showLoading) {
      await this.createLoading();
      await this.loading.present();
    }

    try {
      const { data } = await this.axiosClient.post(url, params, {
        headers: authHeader(),
      });

      return data;
    } catch (error: any) {
      let msg: string = error.response?.data.message;

      if (msg === undefined || msg.length === 0) {
        msg = 'Se ha producido un error inesperado. Vuelva a intentarlo más tarde.';
      }

      this.openToast(
        await this.capitalizeMessage(msg, true)
      );
    } finally {
      if (showLoading) this.loading.dismiss();
    }
  }

  public async capitalizeMessage(input, lowercaseBefore) {
    input = input === undefined || input === null ? "" : input;
    if (lowercaseBefore) {
      input = input.toLowerCase();
    }
    return input
      .toString()
      .replace(/(^|\. *)([a-z])/g, function (match, separator, char) {
        return separator + char.toUpperCase();
      });
  }
}

export const baseHttpService = new BaseHttpService();
