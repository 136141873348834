import { baseHttpService } from "./BaseHttpService";
import UserDto from "@/dto/user-dto";

export async function login(user: UserDto) {   
    return await baseHttpService.post("Security/Login", user, true);
}

export async function logoutFirebase(firebaseToken: string) {   
    return await baseHttpService.post("Security/Logout", {FirebaseToken: firebaseToken}, true);
}

export async function updateTokenFirebase(firebaseToken: string) {   
    return await baseHttpService.post("Security/TokenFirebase", {FirebaseToken: firebaseToken}, true);
}

export async function getUsuarioByToken(token: string) {
    return await baseHttpService.get("Security/GetUsuarioByToken", {token: token}, true);
}
