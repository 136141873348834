export default class CalculoMetricaDto {
  FarmaciaId: number;
  IndicadorId: number;
  EmpresaId: number;
  AlmacenId: number;
  TabId: number;
  FechaIndicador: Date;
  FechaSnapshot: Date;
  VersionSnapshot: number;
  FechaHasta: Date;
  Iva: boolean;
  IndicadorNombre: string;
  
  constructor() {
    this.EmpresaId = 0;
    this.FarmaciaId = 0;
    this.AlmacenId = 0;
    this.TabId = 0;
    //  this.FechaIndicador = new Date("2022-11-09T11:31:00.000Z");
    //  this.FechaSnapshot = new Date("2022-11-09T11:31:00.000Z");
    this.FechaIndicador = new Date();
    this.FechaSnapshot = new Date();
    this.FechaHasta = this.FechaIndicador;
    this.IndicadorId = 0;
    this.IndicadorNombre = "";
    this.VersionSnapshot = 0;
    this.Iva = false;
  }
}

