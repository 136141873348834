import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e63aca0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_header, { translucent: "true" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, { color: "danger" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(" Filtros ")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.applyFilters())),
                  strong: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Cerrar")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_row, { class: "row-filter" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, {
                  "col-6": "",
                  "no-padding": "",
                  "no-margin": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(" Empresa ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, {
                  "col-6": "",
                  "no-padding": "",
                  "no-margin": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_select, {
                          interface: "action-sheet",
                          modelValue: _ctx.empresaId,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.empresaId) = $event)),
                          cancelText: "Cancelar"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.empresas, (item) => {
                              return (_openBlock(), _createBlock(_component_ion_select_option, {
                                key: item.EmpresaId,
                                value: item.EmpresaId
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.EmpresaCodigo), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_row, { class: "row-filter" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, {
                  "col-6": "",
                  "no-padding": "",
                  "no-margin": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(" Almacén ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, {
                  "col-6": "",
                  "no-padding": "",
                  "no-margin": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_select, {
                          interface: "action-sheet",
                          modelValue: _ctx.almacenId,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.almacenId) = $event)),
                          cancelText: "Cancelar"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.almacenes, (item) => {
                              return (_openBlock(), _createBlock(_component_ion_select_option, {
                                key: item.AlmacenId,
                                value: item.AlmacenId
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.AlmacenCodigo), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_row, { class: "row-filter" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, {
                  "col-6": "",
                  "no-padding": "",
                  "no-margin": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { class: "ion-float-left" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Fecha ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, {
                  "col-6": "",
                  "no-padding": "",
                  "no-margin": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.dateSelectedString), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_row, { class: "row-filter" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, {
                  "col-12": "",
                  "no-padding": "",
                  "no-margin": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_component_ion_datetime, {
                          locale: "es-ES",
                          "first-day-of-week": "1",
                          presentation: _ctx.datePresentation,
                          modelValue: _ctx.dateSelectedFormated,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dateSelectedFormated) = $event)),
                          key: _ctx.datePresentation
                        }, null, 8, ["presentation", "modelValue"]))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, {
                  "col-12": "",
                  "no-padding": "",
                  "no-margin": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          size: "default",
                          id: "btnAplicarFecha",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.applyFilters()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Aplicar")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}