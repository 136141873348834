import { useAuthStore } from '@/store/auth';

export default function authHeader() {
  const store = useAuthStore();
  if (store.token) {
 
    return { Authorization: 'Bearer ' + store.token };
  } else {
    return {};
  }
}