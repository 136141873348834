export default class ChangePasswordDto {
    NombreUsuario: string
    Clave: string
    NuevaClave: string
    NuevaClaveRepetida: string

    constructor() {
      this.NombreUsuario = '';
      this.Clave = '';
      this.NuevaClave = '';
      this.NuevaClaveRepetida = '';
    }
  }