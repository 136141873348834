import CalculoMetricaDto from '@/dto/calculo-metrico-dto';
import { baseHttpService } from "./BaseHttpService";

export async function getSnapshot(param: CalculoMetricaDto) : Promise<any> {
    try {
        const data = await baseHttpService.get('Snapshots/GetSnapshot', param, false);

        return [data, null];
        
    } catch(error) {
        return [null, error];
    }
}

export async function getSnapshotVersiones(farmaciaId: number, fecha: Date): Promise<any> {
    try {
        const data = await baseHttpService.get('Snapshots/GetVersionesSnapshot', { farmaciaId, fecha });
        return [data, null];
        
    } catch(error) {
        return [null, error];
    }
}