export default class UserDto {
  NombreUsuario: string
  Password: string
  CodigoFarmacia: string
  NombreFarmacia: string
  TokenFirebase: string
  SolicitarPass: boolean
  constructor() {
    this.NombreUsuario = '';
    this.Password = '';
    this.CodigoFarmacia = '';
    this.NombreFarmacia = '';
    this.TokenFirebase = '';
    this.SolicitarPass = false;
  }
}
