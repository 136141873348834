import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";
import { MessagePayload, Messaging, getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { toastController } from "@ionic/vue";
import { updateTokenFirebase } from "@/services/SecurityService";
import { useAuthStore } from "@/store/auth";
import { useFiltrosStore } from "@/store/filtros";
import { getSolicitudDatosPendiente } from "@/services/UsuarioService";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN_,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

let messaging: Messaging;

if(Capacitor.getPlatform() ==='web')
{  
  setFirebaseWeb();
}
else 
{
  setAndroidFirebase();
}

async function setAndroidFirebase() {
  
  PushNotifications.requestPermissions().then(async (result) => {
    if (result.receive === "granted") {
      // await loading.present();
      await PushNotifications.register();
    } else {
      // await loading.dismiss();
    }
  });

  // On success, we should be able to receive notifications
  PushNotifications.addListener("registration", async (token: Token) => {
    await setUsuarioByToken(token.value);
    // await loading.dismiss();
  });

  // Some issue with our setup and push will not work
  // eslint-disable-next-line
  PushNotifications.addListener("registrationError", async (error: any) => {
    alert('Se ha producido un error y no se recibirán notificaciones.');
    // alert('Error on registration: ' + JSON.stringify(error));
    // await loading.dismiss();
  });

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener("pushNotificationReceived", async (notification: PushNotificationSchema) => {
    // eslint-disable-next-line
    const toast = await toastController.create({
      header: notification.title,
      message: notification.body,
      duration: 6000,
    });
    const store = useFiltrosStore();
    store.setNotificacionesSinLeer(store.notificacionesSinLeer + 1);

    if (notification.data?.tipo) {
      updateBotonActualizarDatos(notification.data.tipo);
    }

    // return toast.present();
  });

  // Method called when tapping on a notification
  PushNotifications.addListener("pushNotificationActionPerformed", async (notification: ActionPerformed) => {
    // eslint-disable-next-line
    const toast = await toastController.create({
      header: notification.notification.title,
      message: notification.notification.body,
      duration: 6000,
    });

    if (notification.notification.data?.tipo) {
      updateBotonActualizarDatos(notification.notification.data.tipo);
    }

    // return toast.present();
  });
}

function setFirebaseWeb() {
  const appfirebase = initializeApp(firebaseConfig);
  
  messaging = getMessaging(appfirebase);
  getToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY })
    .then(async (currentToken) => {
      if (currentToken) {
        await setUsuarioByToken(currentToken);
      }
    });

  onMessage(messaging, function(payload: MessagePayload) {
    console.log(payload);

    const notificacion = async (title: string, body: string) => {
      const toast = await toastController.create({
        header: title,
        message: body,
        duration: 6000,
      });

      toast.present();
    }

    if (payload.data?.tipo) {
      updateBotonActualizarDatos(payload.data.tipo);
    }

    if (payload.notification) {
      notificacion(payload.notification.title, payload.notification.body);
    }
  });
}

function updateBotonActualizarDatos(tipoNotificacion: string) {
  switch (tipoNotificacion) {
    case 'DATOS_ACTUALIZADOS_BAJO_DEMANDA_OK':
    case 'DATOS_ACTUALIZADOS_BAJO_DEMANDA_VENCIDA':
    case 'DATOS_ACTUALIZADOS_BAJO_DEMANDA_ERROR': {
      const storeAuth = useAuthStore();
      const store = useFiltrosStore();
      
      getSolicitudDatosPendiente(storeAuth.codigoFarmacia).then((x) => {
        store.setHaySolicitudDatosPendiente(x);
      });

      if (tipoNotificacion === 'DATOS_ACTUALIZADOS_BAJO_DEMANDA_OK') {
        store.updateVersionesSnapshots();
      }

      break;
    }  
    default:
      break;
  }
}

async function setUsuarioByToken(token: string) {
  const store = useAuthStore();
  store.tokenFirebase = token;
  if(store.token.length > 0)
  {
    updateTokenFirebase(store.tokenFirebase)
  }
}

export default messaging;
