
import { defineComponent } from 'vue';
import { DxChart, DxSeries, DxCommonSeriesSettings, DxLegend, DxTitle, DxTooltip, DxZoomAndPan } from 'devextreme-vue/chart';
import { useFiltrosStore } from '../../store/filtros/index';

export default defineComponent({
  components: {
    DxSeries,
    DxLegend,
    DxCommonSeriesSettings,
    DxTitle,
    DxTooltip,
    DxChart,
    DxZoomAndPan
  },
  props: {
    data: Array<any>(),
  },
  setup(props) {
    const store = useFiltrosStore();
    let indexSerie = 0;

    const customizeTooltip = ({argumentText, seriesName, value}) => {
      const formattedValue = value.toLocaleString(process.env.VUE_APP_CULTURE);

      return {
        text: `${seriesName}: ${argumentText}<br/>${formattedValue} €`
      }
    }

    // eslint-disable-next-line
    const customizeLabel = ({ argument, value }) => {
      return `${value.toLocaleString(process.env.VUE_APP_CULTURE)} %`;
    }

    // eslint-disable-next-line
    const customizePoint = ({ argument, value }) => {
      let color = props.data[indexSerie].color;
      indexSerie++;
      return {
        color: color
      };
    }

    return {
      customizeLabel,
      customizePoint,
      store,
      customizeTooltip
    }
  }
});
