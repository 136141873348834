import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_Header, {
        "page-title": _ctx.store.currentTab ? _ctx.store.currentTab.TabNombre : '',
        "css-class": "main-header",
        onOpenFilter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openFilters())),
        onChangeMenuSelected: _ctx.changeMenuSelected
      }, null, 8, ["page-title", "onChangeMenuSelected"]),
      _createVNode(_component_ion_row, { class: "row-filter" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, {
            size: "6",
            "no-padding": "",
            "no-margin": "",
            class: "ion-no-padding"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, {
                    class: "snapshot",
                    "text-wrap": ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Actualizado el "),
                      _createElementVNode("strong", null, _toDisplayString(_ctx.versionSnapshot), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, {
            size: "6",
            "no-padding": "",
            "no-margin": "",
            class: "ion-no-padding"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                lines: "none",
                class: "ion-align-items-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    style: {"margin":"0 auto"},
                    disabled: _ctx.store.haySolicitudDatosPendiente,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newDataUpdateRequest()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Actualizar datos")
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_tabs, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_router_outlet),
              _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTabsWithoutDashboard(), (tab) => {
                    return (_openBlock(), _createBlock(_component_ion_tab_button, {
                      key: tab.TabId,
                      onClick: ($event: any) => (_ctx.selectTab(tab.TabNombre, tab.TabId)),
                      tab: 'tab' + tab.TabId,
                      href: "/tabs/master",
                      disabled: _ctx.checkTabDisabled(tab.TabId),
                      class: _normalizeClass([tab.TabNombre === (_ctx.store.currentTab ? _ctx.store.currentTab.TabNombre : _ctx.classTabNoSelected) ? _ctx.classTabSelected : _ctx.classTabNoSelected])
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_component_ion_icon, {
                          icon: _ctx.getIcon(tab.TabIcon),
                          key: tab.TabIcon
                        }, null, 8, ["icon"])),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(tab.TabNombre), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick", "tab", "disabled", "class"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}